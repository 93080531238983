// Core
@import "../../../styles/core/_breakpoints";
@import "../../../styles/core/_colors";
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_typography";
@import "../../../styles/core/_animations";
// ProfilePopoverContent

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;



  .top {
    gap: 16px;
  }

  .general {
    gap: 12px;
  }

  .linkText {
    color: var(--text-muted-color);
    cursor: pointer;
  }

  .name {
    text-align: center;
    .font-semibold-24
  }

  .country {
    //display: flex;
    //align-items: center;
    gap: 8px;
    color: var(--text-alt-color);
  }

  .content {
  }

  .panelHeading {
    .font-semibold-12;
    color: var(--text-alt-color);
  }

  .added {
    .font-regular-12;
    color: var(--text-muted-color);
  }

  .avatar {
    color: white;
    background: linear-gradient(45deg, #f9e2ff, #d2f1ff);
    box-shadow: 0 10px 20px -10px #f3e5ff, 0 2px 1px rgb(221 207 250);
  }
}