// Core
@import "../../../styles/core/_breakpoints";
@import "../../../styles/core/_colors";
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_typography";
@import "../../../styles/core/_animations";
.wrapper {
  display: inline-flex;
  font-size: 1rem;
  gap: 5px;
  align-items: center;
  white-space: nowrap;
}

.icon{
  color: currentColor;
  width: 16px;
  height: 16px;
  //shape-rendering: geometricPrecision; <-- this is default value!
  //-webkit-transform: translate3d(0, 0, 0); <-- testing: bluring
  //-webkit-backface-visibility: hidden; <-- testing: bluring
}