// Core
@import "../../../styles/core/_breakpoints";
@import "../../../styles/core/_colors";
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_typography";
@import "../../../styles/core/_animations";
.wrapper {
  text-align: center;
  padding-bottom: 12px;
}

.lock {

  color: var(--brand-color);
  margin-bottom: 12px;
  svg {
    width: 40px;
    height: 40px;
  }
}

.text {
  margin-bottom: 24px;
}
