// Core
@import "../../../styles/core/_breakpoints";
@import "../../../styles/core/_colors";
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_typography";
@import "../../../styles/core/_animations";
// ProfilePopover

.wrapper {
  cursor: pointer;
}
  .content {
    min-width: 268px;
    max-width: 360px;
  }
