:root {
    --xs: @breakpoint-xs;
    --sm: @breakpoint-sm;
    --md: @breakpoint-md;
    --lg: @breakpoint-lg;
    --xl: @breakpoint-xl;
    --xxl: @breakpoint-xxl;

    // Layout

    --header-height: 70px;

    --sidebar-width: 210px;
    --sidebar-width-collapsed: 67px;

    --container-width: 1440px;
    --container-padding: 0 16px;
    --container-padding-md: 0 15px;

    --border-radius-base: 8px;
    --button-border-radius: var(--border-radius-base);

    // Colors  !!! Sync with _constants.ts

    --brand-color: #406ee4;
    --brand-content-color: #fff;
    --card-default-shadow: 0px 4px 8px 2px #a7abbc0d;
    --card-selected-shadow: 0px 4px 8px 2px #6080d61a;

    --cta-button-bg: var(--brand-color);
    --cta-button-content-color: var(--brand-content-color);
    --cta-button-shadow: 0px 2px 5px rgba(var(--brand-color-rgba), 0.25);
    --cta-button-shadow-hover: 0px 3px 6px rgba(var(--brand-color-rgba), 0.25);

    --button-gradient: linear-gradient(128.93deg, #f9e2ff 14.18%, #406ee4 88.45%);

    --heading-font-family: "Nunito", "SFProText", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
    --text-main-color: #32343a;
    --text-heading-color: var(--text-main-color);
    --text-alt-color: #6d7588;
    --text-muted-color: #a8adbb;

    --site-bg-color: #f9fafd;
    --site-border-color: #e4e8f1;
    --header-bg-color: #fff;
    --header-bg-shadow: 0px 4px 4px rgba(217, 226, 255, 0.05); // todo: use it
    --widget-bg-color: #fff;
    --widget-bg-shadow: 0px 1px 1px rgba(0, 23, 103, 0.03), 0px 4px 10px rgba(0, 23, 103, 0.05);
    --widget-bg-alt-color: #f8f9ff;
    --widget-bg-alt-shadow: #f8f9ff; // todo: use it
    --disabled-bg-color: #f3f4f7;

    --muted-color: #e7ecf9; // todo: ?????
    --link-color: var(--brand-color);

    --success-color: #78cd62;
    --warning-color: #ffc53d;
    --info-color: #40a9ff;
    --processing-color: #40a9ff;
    --unable-color: #bec7da;
    --danger-color: #d63636;
    --error-color: var(--danger-color);

    --untouched-status-color: var(--warning-color);
    --claimed-status-color: var(--warning-color);
    --draft-status-color: var(--warning-color);
    --not-imposed-status-color: var(--warning-color);
    --pending-status-color: #9254de;
    --in-work-status-color: var(--processing-color);
    --up-for-grabs-status-color: var(--processing-color);
    --completed-status-color: var(--success-color);
    --submitted-status-color: var(--success-color);
    --ready-status-color: var(--success-color);
    --resolved-status-color: var(--success-color);
    --credited-status-color: var(--success-color);
    --aborted-status-color: var(--danger-color);
    --removed-status-color: var(--danger-color);
    --unable-to-commence-status-color: var(--danger-color);
    --unable-to-proceed-status-color: var(--danger-color);
    --withdrawn-status-color: var(--danger-color);
    --declined-status-color: var(--danger-color);
    --disputed-status-color: var(--danger-color);
    --canceled-status-color: var(--danger-color);
    --refunded-status-color: var(--danger-color);

    --customer-color: #722ed1;
    --writer-color: #5abd4a;
    --support-color: #faad14;

    --badge-bg-color: var(--danger-color);
    --badge-content-color: var(--brand-content-color);

    // Extra features
    --extra-feature-type-1-bg: linear-gradient(245.54deg, rgba(171, 217, 255, 0.3) 1.71%, rgba(195, 108, 217, 0.3) 96.3%);
    --extra-feature-type-1-icon: "/img/extra-features/default/type-1.png";
    --extra-feature-type-1-color: #6d7588;

    --extra-feature-type-2-bg: linear-gradient(67.85deg, #ffd089 0%, #7dea6b 112.03%);
    --extra-feature-type-2-icon: "/img/extra-features/default/type-2.png";
    --extra-feature-type-2-color: #fff;

    --extra-feature-type-3-bg: linear-gradient(245.28deg, #ffcd83 0%, #ff84dc 100%), linear-gradient(245.28deg, #ffedab 0%, #d738ff 100%);
    --extra-feature-type-3-icon: "/img/extra-features/default/type-3.png";
    --extra-feature-type-3-color: #fff;

    --extra-feature-type-4-bg: linear-gradient(107.79deg, #fff383 2.8%, #f48d52 95.04%);
    --extra-feature-type-4-icon: "/img/extra-features/default/type-4.png";
    --extra-feature-type-4-color: #fff;

    --extra-feature-type-5-bg: linear-gradient(249.19deg, #84fff8 8.1%, #6b96ea 97.46%);
    --extra-feature-type-5-icon: "/img/extra-features/default/type-5.png";
    --extra-feature-type-5-color: #fff;

    --extra-feature-type-6-bg: linear-gradient(78.22deg, #4CEEEE 20.59%, #6EF7DE 50.98%, #81F5BD 92.8%);
    --extra-feature-type-6-icon: "/img/extra-features/default/type-6.png";
    --extra-feature-type-6-color: #fff;
}
