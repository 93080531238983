// Core
@import "../../../styles/core/_breakpoints";
@import "../../../styles/core/_colors";
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_typography";
@import "../../../styles/core/_animations";
// Navigation

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.menu {
    &Name {
        .font-medium-10;
        color: var(--text-muted-color);
        text-transform: uppercase;
        padding: 0 @sidebar-padding-x;
    }

    &Item {
        position: relative;
        overflow: hidden;

        &:hover, &.active {
            background-color: var(--widget-bg-alt-color);
            &:after {
                content: "";
                position: absolute;
                display: block;
                left: calc(100% - 3px);
                top: 0;
                bottom: 0;
                width: 3px;
                background-color: var(--brand-color);
            }
            .menuItemText {
                color: var(--link-color);
            }
        }

        &Link {
            display: block;
            padding: 10px @sidebar-padding-x;
        }

        &Text {
            padding-left: 10px;
            .font-semibold-14;
            color: var(--text-main-color);
            white-space: nowrap;
            transform: none;
            opacity: 1;
            transition: transform .3s, opacity .5s;

            .collapsed & {
                transform: translateX(var(--sidebar-width));
                width: 0;
                opacity: 0;
                padding-left: 0;
            }
        }

    }
}