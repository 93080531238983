.font-semibold-42 {
    .fonts(42px, 119%, 600);
}

.font-semibold-34 {
    .fonts(34px, 124%, 600);
}

.font-semibold-30 {
    .fonts(30px, 127%, 600);
}

.font-semibold-24 {
    .fonts(24px, 133%, 600);
}

.font-semibold-20 {
    .fonts(20px, 140%, 600);
}

.font-semibold-18 {
    .fonts(18px, 133%, 600);
}

.font-semibold-16 {
    .fonts(16px, 150%, 600);
}

.font-semibold-14 {
    .fonts(14px, 157%, 600);
}

.font-semibold-12 {
    .fonts(12px, 167%, 600);
}

.font-medium-30 {
    .fonts(30px, 127%, 500);
}

.font-medium-16 {
    .fonts(16px, 150%, 500);
}

.font-medium-14 {
    .fonts(14px, 171%, 500);
}

.font-medium-10 {
    .fonts(10px, 220%, 500);
}

.font-regular-12 {
    .fonts(12px, 167%, 400);
}

.font-regular-14 {
    .fonts(14px, 157%, 400);
}

.font-regular-16 {
    .fonts(16px, 150%, 400);
}

.font-regular-18 {
    .fonts(18px, 140%, 400);
}

.font-regular-20 {
    .fonts(20px, 140%, 400);
}

.font-regular-42 {
    .fonts(42px, 119%, 400);
}
