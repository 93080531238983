.option-description {
    .flexCenter;
    justify-content: space-between;
    font-size: 0.8rem;

    div {
        .flexCenter;
        white-space: nowrap;
        gap: 7px;
    }

    dt {
        //color: @term-color;
    }
}

.ant-input-textarea-show-count::after {
    font-size: 11px;
    font-weight: 600;
    color: var(--text-muted-color);
}

// Grid cards (tmp => move to mixins)
.grid-cards {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));

    &.grid-large {
        grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    }

    &.grid-normal {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }

    &.grid-small {
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }

    &.grid-xs {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
}

.flex-grid {
    display: flex;
    gap: 20px;
    grid-gap: 20px;
}

.spin-fast {
    animation: fa-spin 0.3s linear infinite;
}

.ant-upload.ant-upload-drag {
    background: var(--widget-bg-alt-color);
}

.ant-modal {
    .sm({
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: calc(var(--vh, 1vh) * 100);
    top: 0;
    width: 100% !important;
    max-width: 100% !important;
    padding-bottom: 0;
    margin: 0;
    transform-origin: 0 0 !important;
  });
}

.ant-modal.ant-zoom-enter,
.ant-modal.ant-zoom-appear {
    .sm({
    opacity: 1 !important;
    animation: none !important;
    overflow: hidden;

    .ant-modal-content {
      animation: 0.25s fromDown !important;
    }
  });
}

.ant-modal.ant-zoom-leave.ant-zoom-leave-active {
    .sm({
    overflow: hidden;
    opacity: 0;
    animation: 0.2s fadeSuddenly !important;

    .ant-modal-content {
      animation: 0.2s toDown !important;
    }
  });
}

.ant-modal-content {
    .sm({
    width: 100%;
    max-width: none;
    border-radius: 8px 8px 0 0;
  });
}

@keyframes fromDown {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes toDown {
    0% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(100%);
    }
}

@keyframes fadeSuddenly {
    0% {
        opacity: 1;
    }

    99% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.DateTimePickerDropdown {
    .sm({
    .ant-picker-datetime-panel {
      display: block;
    }

    .ant-picker-time-panel .ant-picker-content {
      height: 94px;
    }

    .ant-picker-panel {
      width: 280px;
    }

    .ant-picker-footer {
      max-width: 100%;
    }

    .ant-picker-date-panel .ant-picker-content {
      width: 100%;
    }

    .ant-picker-content {
      display: block;
      padding: 0 12px;


    }


    .ant-picker-time-panel-column {
      display: flex;
      width: 100%;
      height: 38px;
      padding: 5px 0;
      overflow: hidden;
      overflow-x: auto;
    }

    .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
      padding: 0 10px;
    }

    .ant-picker-time-panel-column:not(:first-child) {
      border-left: none;
    }
  });
}

.SearchDateRangeDropdown {
    .ant-picker-panel {
        &:last-child {
            width: 0;

            .ant-picker-header {
                position: absolute;
                right: 0;

                .ant-picker-header-prev-btn,
                .ant-picker-header-view {
                    visibility: hidden;
                }
            }

            .ant-picker-body {
                display: none;
            }

            @media (min-width: 768px) {
                width: 280px !important;
                .ant-picker-header {
                    position: relative;

                    .ant-picker-header-prev-btn,
                    .ant-picker-header-view {
                        visibility: initial;
                    }
                }

                .ant-picker-body {
                    display: block;
                }
            }
        }
    }
}

.SearchDateRange {
    .ant-picker-clear {
        opacity: 0 !important;
    }

    .ant-picker-range {
        width: 100%;
    }
}

.core-search-engine-mobile-menu {
    background: transparent;

    &__item {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}

#chat[class^="Messenger_chat"] {
    .ant-tabs-nav {
        background: var(--site-bg-color);
    }
}

// Alerts
.sm({
  .ant-alert {
    &.ant-alert-info {
      display: flex;
      flex-flow: column;
      gap: 10px;
      align-items: flex-start;
      text-align: left;
    }

    .ant-alert-action {
      margin: 0;
    }

    .anticon {
      font-size: 18px;
    }
  }
});
