// Core
@import "../../../styles/core/_breakpoints";
@import "../../../styles/core/_colors";
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_typography";
@import "../../../styles/core/_animations";
// ErrorPage

.wrapper {
  padding: 1rem;

  // Center error page content
  height: calc(100% - 200px); // visually centered! -> coz 200px +- icon height
  display: flex;
  align-self: center;
  justify-content: center;

  // .container
  > div:first-of-type {
    display: flex;
  }

  .content {
    text-align: center;
  }

  .heading {
    .font-regular-42;
    .sm({
      .font-semibold-24;
    });
  }

  .icon svg {
    margin: 0 auto;

    path {
      fill: black;
      opacity: .8;
    }
  }

  .text {
    .font-regular-20;
    max-width: 800px;
    color: var(--text-alt-color);
    .sm({
      .font-regular-16;
    });
  }
}
