// Core
@import "../../../styles/core/_breakpoints";
@import "../../../styles/core/_colors";
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_typography";
@import "../../../styles/core/_animations";
// ProfileAvatar

.picture {
    .defaultShadows();
}

.clickable {
    cursor: pointer;

    &:hover {
        filter: brightness(1.02);
    }
}
