// Core
@import "../../../styles/core/_breakpoints";
@import "../../../styles/core/_colors";
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_typography";
@import "../../../styles/core/_animations";
// Header

//background: @component-background; // todo: then use vars
.wrapper {
    padding: 15px 0;
    // background: var(--header-bg-color);
    background: rgba(#fff, 0.85);
    backdrop-filter: blur(4px);
    box-shadow: var(--header-bg-shadow), 0 1px 1px #f3f6ff;
}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
