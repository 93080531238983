// AppLink styles
.link {
    display: inline-flex;
    align-items: center;
    transition: color 0.2s ease;
    color: var(--text-main-color);
    white-space: nowrap;


    &:active, &.active {
        color: var(--brand-color);
    }
}

