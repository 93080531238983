// Core
@import "../../../styles/core/_breakpoints";
@import "../../../styles/core/_colors";
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_typography";
@import "../../../styles/core/_animations";
// AppBaseLayout

.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.header {
    position: sticky;
    top: 0;
    z-index: 10;
}

.main {
    display: flex;
    gap: 14px;
    flex-grow: 1;
}

.page {
    margin: 0;
    padding-bottom: 20px;
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

.content {
    flex-grow: 1;
}

.footer {
    & {
        font-size: 12px;
        color: var(--muted-color-text);
        opacity: 0.45;
        transition: opacity 0.15s ease;

        &:hover {
            opacity: 1;
        }
    }

    &Content {
        padding: 40px 0;
        display: flex;
        border-top: 1px solid rgba(@border-color, 0.8);
        align-items: center;
        justify-content: space-between;

        .sm({
            padding-bottom: 50px;
        });

        button {
            font-size: 12px;
            font-weight: 400;
        }
    }
}
