// Core
@import "../../../styles/core/_breakpoints";
@import "../../../styles/core/_colors";
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_typography";
@import "../../../styles/core/_animations";
// Container

.wrapper {
    padding: var(--container-padding);
    margin: 0 auto;
    .md({
        padding: var(--container-padding-md);
    });
}

.left {
    .leftContainer();
}
