div.ant-table {
    border-radius: 5px;
    background: #fff;
    border: 1px solid @border-color;
    overflow: auto;

    .ant-table-row {
        &:last-child {
            td {
                border: none; // perfect view
            }
        }
    }

    .ant-table {
        margin: 0 !important; // attn! expandable tables -> reset default margin
    }
}

.ant-table-body {
    overflow-x: auto !important;
}
