// Core
@import "../../../styles/core/_breakpoints";
@import "../../../styles/core/_colors";
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_typography";
@import "../../../styles/core/_animations";
.button {
    background-image: linear-gradient(to right, rgb(229, 45, 39) 0%, rgb(179, 18, 23) 51%, rgb(229, 45, 39) 100%);
    background-size: 200% auto;
    transform-origin: center;
    will-change: transform, opacity;
    transform: translateX(0) scale(1) rotate(0deg);
    transition: transform 300ms ease, opacity 100ms ease, visibility 0ms linear 0ms, bottom 0ms linear 0ms, background-position 0.5s;
    border-radius: 100px;
    border-width: 0px;
    right: 20px;
    bottom: 100px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.16) !important;
    cursor: pointer;
    height: 64px;
    padding: 0px;
    position: fixed;
    user-select: none;
    width: 64px;
    z-index: 2147483000 !important;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-position: 50%;
        box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4) !important;
    }
}

.bell {
    display: block;
    width: 24px;
    height: 24px;
    animation: ring 2s ease infinite;
}

@keyframes ring {
    0% {
        -webkit-transform: rotate(-15deg);
        -ms-transform: rotate(-15deg);
        transform: rotate(-15deg);
    }

    2% {
        -webkit-transform: rotate(15deg);
        -ms-transform: rotate(15deg);
        transform: rotate(15deg);
    }

    4% {
        -webkit-transform: rotate(-18deg);
        -ms-transform: rotate(-18deg);
        transform: rotate(-18deg);
    }

    6% {
        -webkit-transform: rotate(18deg);
        -ms-transform: rotate(18deg);
        transform: rotate(18deg);
    }

    8% {
        -webkit-transform: rotate(-22deg);
        -ms-transform: rotate(-22deg);
        transform: rotate(-22deg);
    }

    10% {
        -webkit-transform: rotate(22deg);
        -ms-transform: rotate(22deg);
        transform: rotate(22deg);
    }

    12% {
        -webkit-transform: rotate(-18deg);
        -ms-transform: rotate(-18deg);
        transform: rotate(-18deg);
    }

    14% {
        -webkit-transform: rotate(18deg);
        -ms-transform: rotate(18deg);
        transform: rotate(18deg);
    }

    16% {
        -webkit-transform: rotate(-12deg);
        -ms-transform: rotate(-12deg);
        transform: rotate(-12deg);
    }

    18% {
        -webkit-transform: rotate(12deg);
        -ms-transform: rotate(12deg);
        transform: rotate(12deg);
    }

    20% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

.code {
    border-radius: 16px;
    position: relative;
    margin-bottom: 20px;
    padding: 10px 15px;
    overflow: hidden;
    cursor: pointer;

    span {
        position: relative;
        z-index: 1;
        font-weight: 700;
        color: var(--text-main-color);
        font-size: 20px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--info-color);
        opacity: 0.2;
    }
}

.discountHeading {
    margin-top: -10px;
}
