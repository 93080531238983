/* SFProText-Regular */
@font-face {
  font-family: "SFProText";
  font-style: normal;
  font-weight: 400;
  src: local(""),
  url("../../media/fonts/SFProText-Regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/SFProText-Regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* SFProText-Medium */
@font-face {
  font-family: "SFProText";
  font-style: normal;
  font-weight: 500;
  src: local(""),
  url("../../media/fonts/SFProText-Medium.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/SFProText-Medium.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* SFProText-Semibold */
@font-face {
  font-family: "SFProText";
  font-style: normal;
  font-weight: 600;
  src: local(""),
  url("../../media/fonts/SFProText-Semibold.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/SFProText-Semibold.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* SFProText-Bold */
@font-face {
  font-family: "SFProText";
  font-style: normal;
  font-weight: 700;
  src: local(""),
  url("../../media/fonts/SFProText-Bold.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/SFProText-Bold.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

