// Core
@import "../../../styles/core/_breakpoints";
@import "../../../styles/core/_colors";
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_typography";
@import "../../../styles/core/_animations";
// SpinFullscreen
.SpinFullscreen {
  :global {
    .ant-spin.ant-spin-lg {
      transform: translate(50%, 50%);
      position: fixed;
      top: 40%;
      right: 50%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      .ant-spin-dot-spin {
        animation-duration: 0.6s; // spin faster
      }

      .ant-spin-dot i {
        animation: resize 1s ease-in-out infinite;
      }

      .ant-spin-dot-item {
        background: var(--brand-color, black);
      }
    }
  }
}
