// Core
@import "../../styles/core/_breakpoints";
@import "../../styles/core/_colors";
@import "../../styles/core/_variables.app";
@import "../../styles/core/_mixins";
@import "../../styles/core/_typography";
@import "../../styles/core/_animations";
// BadgeCounter
.counterVariant(@text-color: white, @bg-color: @danger-color, @shadow-color:@bg-color) {
    :global {
        .ant-badge-count {
            font-weight: 600;
            height: 19px;
            min-width: 19px;
            line-height: 19px;

            &:not([class*="ant-badge-status"]) {
                color: @text-color;
                background: @bg-color;
                box-shadow: 0 5px 4px -2px rgba(@shadow-color, 0.5), 0 1px 1px -1px @shadow-color;
            }
        }
    }
}

.wrapper {
    &.default {
        .counterVariant(#5c6c78, #fff, #b7ccdc);
    }

    &.alert {
        .counterVariant();
    }
}

.prefix:not(:empty) {
    padding-right: 0.3rem;
}
