// Core
@import "../../../styles/core/_breakpoints";
@import "../../../styles/core/_colors";
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_typography";
@import "../../../styles/core/_animations";
// Sidebar

.wrapper {
    position: sticky;
    top: var(--header-height);
    z-index: 2;

    height: calc(100vh - var(--header-height));

    padding-top: @sidebar-padding-y;

    flex-shrink: 0;

    display: flex;
    flex-direction: column;

    background-color: var(--header-bg-color);
    box-shadow: var(--header-bg-shadow);

    width: var(--sidebar-width);
    transition: width 0.2s;

    &.collapsed {
        width: var(--sidebar-width-collapsed);
    }
}

.menus {
    overflow-y: auto;
    overflow-x: visible;
    .customizeScrollbars(0);
}

.collapseButton {
    padding: @sidebar-padding-y 0;
    margin-top: auto;
}
