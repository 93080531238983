// Core
@import "../../../styles/core/_breakpoints";
@import "../../../styles/core/_colors";
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_typography";
@import "../../../styles/core/_animations";
.burger {
  padding: 0 !important;
  position: relative;
  svg {
    width: 32px;
    height: 32px;

  }


  &.burgerMark {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;

      width: 11px;
      height: 11px;
      border-radius: 50%;
      background-color: var(--badge-bg-color);
      border: 1px solid var(--badge-content-color)
    }
  }
}

.drawer {
  :global {
    .ant-drawer-content-wrapper {
      .ant-drawer-header-title {
        flex-direction: row-reverse;
        color: var(--text-main-color);

      }

      .ant-drawer-title {
        .font-semibold-24;
      }

      .ant-drawer-close {
        margin-right: 0;
      }

      .ant-drawer-header {
        border-bottom: none;
        padding: 24px 16px 16px;
      }

      .ant-drawer-body {
        padding: 24px 0;
      }
    }

  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 50px;
}

.orderBtn {
  padding: 0 16px;
  display: block;
  margin-bottom: 12px;

  button {
    width: 100%;
  }
}


.user {
  margin-top: auto;
  padding: 15px 16px;
  &Name {
    .font-semibold-14;
    color: var(--text-main-color);
  }
}