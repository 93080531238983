// Core
@import "../../../../styles/core/_breakpoints";
@import "../../../../styles/core/_colors";
@import "../../../../styles/core/_variables.app";
@import "../../../../styles/core/_mixins";
@import "../../../../styles/core/_typography";
@import "../../../../styles/core/_animations";
.wrapper {
    height: 66px;
    overflow: hidden;
    z-index: 9;
    margin-left: -14px;
    position: sticky;
    top: 70px;
    max-height: 0;
    transition: max-height 0.1s 0.5s;

    &.wrapperVisible {
        transition: max-height 0.1s;
        max-height: 66px;
    }

    .md({
        margin-left: 0;
    });

    button {
        cursor: pointer;
    }

    .xs({
        min-height: 50px;
        height: auto;
    });
}
.main {
    height: 66px;
    transform: translateY(-105%);
    transition: transform 0.5s;

    .xs({
        min-height: 50px;
        height: auto;
    });

    //background-repeat: repeat-x;
    background-size: 200% 100%;

    animation: AnimationName 4s linear infinite;

    .media-breakpoint(1300px, {
        top: 78px;
    });

    &.visible {
        transform: translateY(0);
    }

    &.variantDraft {
        background-image: linear-gradient(90deg, #ffa800 0%, #ffd600 25%, #ffa800 50%, #ffd600 75%, #ffa800 100%);

        .timerTitle,
        .contentMobile {
            color: #1c1e25;
        }

        .timerItem {
            background-color: #17b5cb;
            color: #fff;
            border-radius: 4px;

            div:last-child {
                color: #e5fcff;
            }
        }

        .orderFormLink {
            background: linear-gradient(180deg, #41c8ce 0%, #008ba8 88.54%);
            box-shadow: 0px 4px 4px 0px #855c2040;
        }

        .offerText {
            color: #1c1e25;
        }

        .offerPrice {
            background: #ff8f00;
            box-shadow: 0 2px 4px 0 #955a2f40;
        }

        .container {
            &::before {
                background-image: url('data:image/svg+xml,<svg width="2317" height="66" viewBox="0 0 2317 66" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_b_11_1217)"><path d="M2300.88 1H1.5V65H2300.88V48C2300.88 48 2316 35.2222 2316 33C2316 30.7778 2300.88 18 2300.88 18V1Z" fill="white" fill-opacity="0.4"/><path d="M1.5 0.5H1V1V65V65.5H1.5H2300.88H2301.38V65V48.2315C2301.49 48.1372 2301.65 48.0004 2301.86 47.8265C2302.27 47.4721 2302.86 46.9634 2303.57 46.346C2304.99 45.1116 2306.88 43.4418 2308.78 41.7015C2310.67 39.9629 2312.57 38.1474 2314 36.6234C2314.72 35.8625 2315.32 35.1644 2315.75 34.5788C2315.97 34.2865 2316.15 34.0115 2316.27 33.7631C2316.4 33.5277 2316.5 33.2606 2316.5 33C2316.5 32.7394 2316.4 32.4723 2316.27 32.2369C2316.15 31.9885 2315.97 31.7135 2315.75 31.4212C2315.32 30.8356 2314.72 30.1375 2314 29.3766C2312.57 27.8526 2310.67 26.0371 2308.78 24.2985C2306.88 22.5582 2304.99 20.8883 2303.57 19.654C2302.86 19.0366 2302.27 18.5279 2301.86 18.1735C2301.65 17.9996 2301.49 17.8628 2301.38 17.7685V1V0.5H2300.88H1.5Z" stroke="%23FFA901"/></g><defs><filter id="filter0_b_11_1217" x="-9.5" y="-10" width="2336.5" height="86" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feGaussianBlur in="BackgroundImageFix" stdDeviation="5"/><feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_11_1217"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_11_1217" result="shape"/></filter></defs></svg>');
            }
        }
    }

    &.variantExtraQuality {
        background-image: linear-gradient(
            90deg,
            #00b6b6 0%,
            #0298b9 8.125%,
            #0035bd 16.875%,
            #4318c0 25%,
            #0035bd 33.125%,
            #0298b9 38.875%,
            #00b6b6 50%,
            #0298b9 58.125%,
            #0035bd 66.875%,
            #4318c0 75%,
            #0035bd 83.125%,
            #0298b9 88.875%,
            #00b6b6 100%
        );
        //background: linear-gradient(90deg, #00B6B6 0%, #0298B9 32.5%, #0035BD 67.5%, #4318C0 100%);

        .timerTitle,
        .contentMobile {
            color: #fff;
        }

        .timerItem {
            background-color: #ffe81e;
            color: #003c43;
            border-radius: 4px;

            div:last-child {
                color: #517a7e;
            }
        }

        .orderFormLink {
            background: linear-gradient(180deg, #ffcf55 0%, #f55fca 100%);
            box-shadow: 0px 4px 4px 0px #05052140;
        }

        .offerText {
            color: #fff;
        }

        .offerPrice {
            background: linear-gradient(270deg, #1fbadc -0.57%, #1fbadc 92.93%, #1fbadc 99.43%);
            box-shadow: 0px 2px 4px 0px #021a414d;
        }

        .container {
            &::before {
                background-image: url('data:image/svg+xml,<svg width="2317" height="66" viewBox="0 0 2317 66" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(%23filter0_b_11_1217)"><path d="M2300.88 1H1.5V65H2300.88V48C2300.88 48 2316 35.2222 2316 33C2316 30.7778 2300.88 18 2300.88 18V1Z" fill="white" fill-opacity="0.4"/><path d="M1.5 0.5H1V1V65V65.5H1.5H2300.88H2301.38V65V48.2315C2301.49 48.1372 2301.65 48.0004 2301.86 47.8265C2302.27 47.4721 2302.86 46.9634 2303.57 46.346C2304.99 45.1116 2306.88 43.4418 2308.78 41.7015C2310.67 39.9629 2312.57 38.1474 2314 36.6234C2314.72 35.8625 2315.32 35.1644 2315.75 34.5788C2315.97 34.2865 2316.15 34.0115 2316.27 33.7631C2316.4 33.5277 2316.5 33.2606 2316.5 33C2316.5 32.7394 2316.4 32.4723 2316.27 32.2369C2316.15 31.9885 2315.97 31.7135 2315.75 31.4212C2315.32 30.8356 2314.72 30.1375 2314 29.3766C2312.57 27.8526 2310.67 26.0371 2308.78 24.2985C2306.88 22.5582 2304.99 20.8883 2303.57 19.654C2302.86 19.0366 2302.27 18.5279 2301.86 18.1735C2301.65 17.9996 2301.49 17.8628 2301.38 17.7685V1V0.5H2300.88H1.5Z" stroke="%230186B0"/></g><defs><filter id="filter0_b_11_1217" x="-9.5" y="-10" width="2336.5" height="86" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feGaussianBlur in="BackgroundImageFix" stdDeviation="5"/><feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_11_1217"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_11_1217" result="shape"/></filter></defs></svg>');
            }
        }
    }
}

.close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;

    .xs({
        top: 8px;
        right: 4px;
    });
}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 30px;
    padding-left: 30px;

    .sm({
         padding-right: 15px;
        padding-left: 15px;
    });

    .xs({
        display: none;
    });

    &::before {
        content: "";
        position: absolute;
        top: 0;
        height: 100%;
        right: calc(100% - 265px);
        width: 2314px;

        background-position: center right;
        background-repeat: no-repeat;
    }
}

.orderFormLink {
    font-weight: 700;
    color: #fff;
    padding: 12px;
    text-align: center;
    width: 168px;
    border-radius: 12px;
    margin-right: 5px;
    transition: transform 0.3s;
    outline: none;
    border: none;
    white-space: nowrap;

    .sm({
        margin-right: 10px;
    });

    &:hover {
        transform: scale(1.1);
    }
}

.offerWrap {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
    margin-right: 40px;
}

.timer {
    display: flex;
}

.timerTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 4px;
    white-space: nowrap;
}

.timer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 13px;
}

.timerItem {
    text-align: center;
    width: 40px;
    height: 30px;
    padding: 4px 0;
    position: relative;

    div {
        &:first-child {
            font-weight: 500;
            line-height: 1;
            font-size: 13px;
        }

        &:last-child {
            font-size: 9px;
            line-height: 1;
        }
    }

    &:not(:last-child) {
        &::after {
            content: "";
            position: absolute;
            top: 7px;
            right: -10px;
            width: 5px;
            height: 19px;
            background-image: url('data:image/svg+xml,<svg width="3" height="10" viewBox="0 0 3 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.26136 9.10795C0.950758 9.10795 0.683712 8.99811 0.460227 8.77841C0.236742 8.55492 0.125 8.28598 0.125 7.97159C0.125 7.66098 0.236742 7.39583 0.460227 7.17614C0.683712 6.95265 0.950758 6.84091 1.26136 6.84091C1.57197 6.84091 1.83902 6.95265 2.0625 7.17614C2.28598 7.39583 2.39773 7.66098 2.39773 7.97159C2.39773 8.17992 2.3447 8.37121 2.23864 8.54545C2.13636 8.71591 2 8.85227 1.82955 8.95455C1.65909 9.05682 1.4697 9.10795 1.26136 9.10795ZM1.26136 2.75568C0.950758 2.75568 0.683712 2.64394 0.460227 2.42045C0.236742 2.19697 0.125 1.92992 0.125 1.61932C0.125 1.30871 0.236742 1.04356 0.460227 0.823863C0.683712 0.600378 0.950758 0.488636 1.26136 0.488636C1.57197 0.488636 1.83902 0.600378 2.0625 0.823863C2.28598 1.04356 2.39773 1.30871 2.39773 1.61932C2.39773 1.82765 2.3447 2.01894 2.23864 2.19318C2.13636 2.36364 2 2.5 1.82955 2.60227C1.65909 2.70455 1.4697 2.75568 1.26136 2.75568Z" fill="white"/></svg>');
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}

.offerText {
    font-size: 16px;
    line-height: 1.2;
}

.middle {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 5px;
    margin-right: 5px;

    .md({
        display: none;
    });
}

:global(.app-sidebar-full) {
    :local {
        .middle {
            .media-breakpoint(1100px, {
                display: none;
            });
        }
    }
}

.offerPrice {
    padding: 10px 20px;
    border-radius: 30px;
    font-weight: 600;
    color: #fff;
    font-size: 20px;
    white-space: nowrap;
}

.timerBorderWrap {
    width: 17px;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
}

@keyframes AnimationName {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: -100% 50%;
    }
    100% {
        background-position: -200% 50%;
    }
}

.contentMobile {
    display: none;
    align-items: center;
    padding: 8px 24px 8px 8px;
    gap: 8px;
    cursor: pointer;
    font-size: 14px;

    s {
        text-decoration: line-through;
    }

    .xs({
        display: flex;
    });
}
