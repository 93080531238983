.ant-card {
    border: 1px solid #fff; // @border-color;
    display: flex;
    flex-flow: column;

    .tinyShadows;

    .ant-card-body {
        flex: 1;
    }

    .ant-card-head {
        background: #fff;
        border-bottom-color: @border-color;

        .ant-card-head-title {
            overflow: visible;
        }
    }

    .ant-card-actions {
        background: var(--widget-bg-alt-color);
        overflow: hidden;
        border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);
        display: flex;

        li {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .ant-card {
        border: 1px solid #ecf0ff;
    }
}
